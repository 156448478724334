import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Close from "@material-ui/icons/Close";
import CategoryList from "./CategoryList";
import "./style.styl";


export default function CategoryDialog(props) {
    const {selected, modelName, itemId, categoryDict, height, onClose} = props;
    const [categorySelected, setCategorySelected] = useState("");
    const items = categoryDict[categorySelected] || categoryDict.main || [];

    const onExpand = (id) => setCategorySelected(id);
    const onSelect = (id, name, view) => {
        props.onSelect(id, name, view);
        onClose();
    };

    return (
        <Dialog
            fullScreen
            aria-labelledby="confirmation-dialog-title"
            open
            onClose={onClose}
            >
            <div className="u-flexCenter u-justifyContentSpaceBetween">
                <div className="u-flexCenter">
                    {
                        categorySelected ?
                            <IconButton className="u-padding5 u-marginLeft15"
                                        onClick={() => setCategorySelected("")}>
                                <ArrowBack/>
                            </IconButton>
                            :
                            null
                    }
                    <DialogTitle>{categorySelected ? "Подкатегории" : "Категории"}</DialogTitle>
                </div>
                <div className="u-flexCenter u-paddingRight15">
                    <IconButton className="u-padding5"
                                onClick={onClose}>
                        <Close/>
                    </IconButton>
                </div>
            </div>
            <DialogContent dividers className="u-paddingTop3 u-padding0">
                <CategoryList   {...props}
                                selected={selected}
                                items={items}
                                onExpand={onExpand}
                                onSelect={onSelect}/>
            </DialogContent>
        </Dialog>
    );
}
