import React, { useState } from "react";

import Menu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

export const locationItems = [
  { value: "AK", name: "Alaska", lat: "63.588753", lon: "-154.493062" },
  { value: "AL", name: "Alabama", lat: "32.318231", lon: "-86.902298" },
  { value: "AR", name: "Arkansas", lat: "35.20105", lon: "-91.831833" },
  { value: "AZ", name: "Arizona", lat: "34.048928", lon: "-111.093731" },
  { value: "CA", name: "California", lat: "36.778261", lon: "-119.417932" },
  { value: "CO", name: "Colorado", lat: "39.5501", lon: "-105.7821" },
  { value: "CT", name: "Connecticut", lat: "41.603221", lon: "-73.087749" },
  {
    value: "DC",
    name: "District Of Columbia",
    lat: "38.905985",
    lon: "-77.033418",
  },
  { value: "DE", name: "Delaware", lat: "38.910832", lon: "-75.52767" },
  { value: "FL", name: "Florida", lat: "27.664827", lon: "-81.515754" },
  { value: "GA", name: "Georgia", lat: "32.157435", lon: "-82.907123" },
  { value: "HI", name: "Hawaii", lat: "19.898682", lon: "-155.665857" },
  { value: "IA", name: "Iowa", lat: "41.878003", lon: "-93.097702" },
  { value: "ID", name: "Idaho", lat: "44.068202", lon: "-114.742041" },
  { value: "IL", name: "Illinois", lat: "40.633125", lon: "-89.398528" },
  { value: "IN", name: "Indiana", lat: "40.551217", lon: "-85.602364" },
  { value: "KS", name: "Kansas", lat: "39.011902", lon: "-98.484246" },
  { value: "KY", name: "Kentucky", lat: "37.839333", lon: "-84.270018" },
  { value: "LA", name: "Louisiana", lat: "31.244823", lon: "-92.145024" },
  { value: "MA", name: "Massachusetts", lat: "42.407211", lon: "-71.382437" },
  { value: "MD", name: "Maryland", lat: "39.045755", lon: "-76.641271" },
  { value: "ME", name: "Maine", lat: "45.253783", lon: "-69.445469" },
  { value: "MI", name: "Michigan", lat: "44.314844", lon: "-85.602364" },
  { value: "MN", name: "Minnesota", lat: "46.729553", lon: "-94.6859" },
  { value: "MO", name: "Missouri", lat: "37.964253", lon: "-91.831833" },
  { value: "MS", name: "Mississippi", lat: "32.354668", lon: "-89.398528" },
  { value: "MT", name: "Montana", lat: "46.879682", lon: "-110.362566" },
  { value: "NC", name: "North Carolina", lat: "35.759573", lon: "-79.0193" },
  { value: "ND", name: "North Dakota", lat: "47.551493", lon: "-101.002012" },
  { value: "NE", name: "Nebraska", lat: "41.492537", lon: "-99.901813" },
  { value: "NH", name: "New Hampshire", lat: "43.193852", lon: "-71.572395" },
  { value: "NJ", name: "New Jersey", lat: "40.058324", lon: "-74.405661" },
  { value: "NM", name: "New Mexico", lat: "34.97273", lon: "-105.032363" },
  { value: "NV", name: "Nevada", lat: "38.80261", lon: "-116.419389" },
  { value: "NY", name: "New York", lat: "43.299428", lon: "-74.217933" },
  { value: "OH", name: "Ohio", lat: "40.417287", lon: "-82.907123" },
  { value: "OK", name: "Oklahoma", lat: "35.007752", lon: "-97.092877" },
  { value: "OR", name: "Oregon", lat: "43.804133", lon: "-120.554201" },
  { value: "PA", name: "Pennsylvania", lat: "41.203322", lon: "-77.194525" },
  { value: "RI", name: "Rhode Island", lat: "41.580095", lon: "-71.477429" },
  { value: "SC", name: "South Carolina", lat: "33.836081", lon: "-81.163725" },
  { value: "SD", name: "South Dakota", lat: "43.969515", lon: "-99.901813" },
  { value: "TN", name: "Tennessee", lat: "35.517491", lon: "-86.580447" },
  { value: "TX", name: "Texas", lat: "31.968599", lon: "-99.901813" },
  { value: "UT", name: "Utah", lat: "39.32098", lon: "-111.093731" },
  { value: "VA", name: "Virginia", lat: "37.431573", lon: "-78.656894" },
  { value: "VT", name: "Vermont", lat: "44.558803", lon: "-72.577841" },
  { value: "WA", name: "Washington", lat: "47.751074", lon: "-120.740139" },
  { value: "WI", name: "Wisconsin", lat: "43.78444", lon: "-88.787868" },
  { value: "WV", name: "West Virginia", lat: "38.597626", lon: "-80.454903" },
  { value: "WY", name: "Wyoming", lat: "43.075968", lon: "-107.290284" },
];

export const LocationWidget = (props) => {
  const { className, isMobile, dispatch } = props;
  const [open, setOpen] = useState(null);

  const selected = props.selected || "WA";
  return (
    <React.Fragment>
      <Tooltip title="Your State">
        <Button
          size="small"
          variant="outlined"
          color={isMobile ? "secondary" : undefined}
          className={"u-round " + (className || "")}
          onClick={(e) => setOpen(e.currentTarget)}
        >
          {selected}
        </Button>
      </Tooltip>
      <Menu
        anchorEl={open}
        className="location-widget-menu"
        open={Boolean(open)}
        onClose={() => setOpen(null)}
      >
        <List>
          {locationItems.map((item, i) => {
            const isSelected = item.value === selected;
            return (
              <ListItem
                key={i}
                button
                selected={isSelected}
                onClick={() => {
                  dispatch("location_widget", {
                    stateSelected: item.value,
                    stateLatitude: item.lat,
                    stateLongitude: item.lon,
                  });
                  setOpen(null);
                }}
              >
                <div className="u-flexCenter">
                  {item.value}
                  <span className="u-textColorNormal u-marginLeft10">
                    {item.name}
                  </span>
                </div>
              </ListItem>
            );
          })}
        </List>
      </Menu>
    </React.Fragment>
  );
};

export const location_widget = {
  stateSelected: "WA",
  stateDistance: 0,
  stateLatitude: "47.751074",
  stateLongitude: "-120.740139",
  latitude: "",
  longitude: "",
};
