import React, {useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import CategoryList from "./CategoryList";
import "./style.styl";


export default function CategoryDrawer(props) {
    const {isOpen, modelName, itemId, categoryFlatDict, categoryDict, categoryParentDict, height, onClose} = props;
    const [categorySelected, setCategorySelected] = useState("");
    const selected = categorySelected || props.selected;

    const onExpand = (id) => setCategorySelected(id);

    const onSelect = () => {
        const view = categoryFlatDict[selected] || {};
        const name = view.product_category.name;
        props.onSelect(selected, name, view);
        onClose();
    };

    const calcItemsOld = () => {
        const items = [];
        if (selected && categoryFlatDict[selected]) {
            items.push([categoryFlatDict[selected]]);
        }

        for (let i = 0; i < 5; i++) {
            const item = items.length !== 0 ? items[0][0] : undefined;
            if (!item || !item.product_category) {
                continue;
            }
            const parentId = item.product_category.parent_id;
            const categoryId = item.product_category.product_category_id;

            (categoryParentDict[categoryId] || []).forEach((v, i) => {
                if (v.product_category.product_category_id === parentId) {
                    items.unshift([v]);
                }
            });
        }
        const child = categoryDict[selected] || (items.length === 0 ? categoryDict.main : undefined);
        if (child) {
            items.push(child);
        }
        return items;
    };

    const calcItems = () => {
        const items = (categoryParentDict[selected] || []).map(v => [v]);
        const child = categoryDict[selected] || (items.length === 0 ? categoryDict.main : undefined);
        if (child) {
            items.push(child);
        }
        return items;
    };

    const withFilter = (items) => items.filter(item => item.product_category.type === "primary" || item.product_category.type === "preview" || !item.product_category.type);

    const items = !isOpen ? [] : calcItems();

    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
        <SwipeableDrawer open={isOpen}
                         className="filter-mobile-widget"
                         onClose={onClose}
                         disableBackdropTransition={!iOS}
                         disableDiscovery={iOS}
                         onOpen={() => null}>

            <div className="u-relative u-sizeFull u-overflowAutoY">
                <div className="u-flexCenter">
                    <DialogTitle className="u-paddingLeft16">{"Категории"}</DialogTitle>
                </div>
                <div className="u-padding0 u-marginBottom80">
                    {
                        items.map((v, i) => {
                            const isFirst = i === 0;
                            const isLast = i === items.length - 1;
                            return (
                                <CategoryList   {...props}
                                                key={i}
                                                selected={selected}
                                                items={withFilter(v)}
                                                level={i}
                                                isFirst={isFirst}
                                                isLast={isLast}
                                                onSelect={onExpand}/>
                            );
                        })
                    }
                </div>
                <div className="action">
                    <div className="u-sizeFullWidth">
                        <Button variant="contained"
                                color="primary"
                                size="large"
                                className="u-sizeFullWidth"
                                disabled={!selected}
                                onClick={onSelect}>
                            Посмотреть
                        </Button>
                    </div>
                </div>
            </div>
        </SwipeableDrawer>
    );
}
