import React, {useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CategoryList from "../CategoryDialog/CategoryList";
import "./style.styl";


export default function CategoryPopover(props) {
    const {isOpen, selected, modelName, itemId, categoryDict, categoryParentDict, height, onClose} = props;
    const [categorySelected, setCategorySelected] = useState("");
    const items = categoryDict[categorySelected] || categoryDict.main || [];

    const onExpand = (id) => setCategorySelected(id);
    const onSelect = (id, name, view) => {
        props.onSelect(id, name, view);
        onClose();
    };

    const onBack = () => {
        let id = "";
        for (let l of Object.values(categoryDict)) {
            l.forEach(v => {
                if (v.product_category.product_category_id === categorySelected) {
                    id = v.product_category.parent_id || "";
                }
            });
            if (id) {
                break;
            }
        }
        setCategorySelected(id);
    };


    const open = Boolean(isOpen);
    const id = open ? "simple-popover" : undefined;

    return (
        <Popover    id={"simple-popover"}
                    open={open}
                    anchorEl={isOpen}
                    onClose={onClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}>

            <div className="u-sizeFullWidth u-maxWidth400 u-minWidth400">
                <div className="u-flexCenter u-justifyContentSpaceBetween u-borderBottom1 u-borderColorLightest">
                    <div className="u-flexCenter">
                        {
                            categorySelected ?
                                <IconButton className="u-padding5 u-marginLeft15"
                                            onClick={onBack}>
                                    <ArrowBack/>
                                </IconButton>
                                :
                                null
                        }
                        <DialogTitle>{categorySelected ? "Подкатегории" : "Категории"}</DialogTitle>
                    </div>

                </div>
                <div className="u-padding0 u-maxHeight400 u-sizeFullHeight u-overflowAutoY">
                    <CategoryList   {...props}
                                    selected={selected}
                                    items={items}
                                    onExpand={onExpand}
                                    onSelect={onSelect}/>
                </div>
            </div>
        </Popover>
    );
}
