export const calcPrice = (obj, featureList) => {
  let price = parseFloat(obj.price || 0);

  (featureList || []).forEach((attribute) => {
    if (attribute.is_priced) {
      (attribute.values || []).forEach((value) => {
        if (value.price) {
          price += value.price;
        }
      });
    }
  });

  let discount = obj.discount_percent
    ? price * (1 - parseFloat(obj.discount_percent) / 100)
    : obj.discount
    ? parseFloat(obj.discount)
    : 0;
  let discount_percent = obj.discount_percent
    ? "-" + obj.discount_percent
    : obj.discount
    ? Math.round((discount / price - 1) * 100)
    : 0;
  let final_price = discount_percent ? discount : price;

  if (final_price < 0 || price < 0 || discount < 0) {
    final_price = 0;
    price = 0;
    discount = 0;
    if (discount_percent) {
      discount_percent = "-100";
    }
  }

  let price_fixed = price.toFixed(2);
  if (price_fixed.endsWith(".00")) {
    //price_fixed = price_fixed.split(".")[0];
  }
  let discount_fixed = discount ? discount.toFixed(2) : "";
  if (discount_fixed.endsWith(".00")) {
    //discount_fixed = discount_fixed.split(".")[0];
  }
  let final_price_fixed = final_price ? final_price.toFixed(2) : "";
  if (final_price_fixed.endsWith(".00")) {
    //final_price_fixed = final_price_fixed.split(".")[0];
  }
  if (price < 0) {
    price_fixed = "Contact our manager for price details";
  }

  return {
    final_price,
    final_price_fixed,
    price,
    discount,
    discount_percent,
    price_fixed,
    discount_fixed,
  };
};

export const renderNameVertical = (item, options) => {
  options = options || {};
  let name = item.model || item.name;
  if (name.length > 70 && !options.isNameFull) {
    name = name.slice(0, 70) + "...";
  }
  let manufacturer = item.manufacturer || "";
  if (options.isManufacturerFull && manufacturer.length > 20) {
    manufacturer = manufacturer.slice(0, 20) + "..";
  }
  const featureList = options.featureList || [];
  return (
    <div className="product-name-vertical u-flexColumn u-sizeFullWidth">
      {options.isNameH1 ? (
        <h1
          className={
            "u-marginBottom4 u-textColorDarker " +
            (options.nameClassName ? options.nameClassName : "")
          }
        >
          {name}
        </h1>
      ) : (
        <h4
          className={
            "u-marginBottom4 u-textColorDarker " +
            (options.nameClassName ? options.nameClassName : "")
          }
        >
          {name}
        </h4>
      )}
      {!options.noContent ? (
        <div
          className="product-name-content u-flexCenter u-flexWrap"
          style={{ color: "#9d9ca5" }}
        >
          <div className="product_property u-marginVertical2">
            {featureList.map((attribute, i) => {
              const isPriced = attribute.is_priced;
              return (
                <div className="u-flex">
                    <span>
                        {attribute.name || ""}:
                    </span>
                    <span className="u-flexColumn">
                        {(attribute.values || []).map((value, i) => {
                          return (
                            <div className="u-marginLeft5">
                              {value.value}{" "}
                              {isPriced && value.price
                                ? "+$" + value.price.toFixed(2)
                                : ""}
                            </div>
                          );
                        })}
                    </span>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};
