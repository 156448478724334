import React, { useEffect, useState } from "react";

import CategoryDropdown from "./CategoryDropdown";
import CategoryDialog from "./CategoryDialog";
import CategoryDrawer from "./CategoryDrawer";
import CategoryPopover from "./CategoryPopover";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Category from "@material-ui/icons/Category";
import ViewHeadline from "@material-ui/icons/ViewHeadline";

import { dataSourceV2 } from "commons-ui/core/DataSource";

export default function CategoryWidget(props) {
  const {
    type,
    category_widget,
    modelName,
    itemId,
    parentItemId,
    parentItemIdValue,
    ecommerceStoreId,
    buttonMode,
    button,
    noButton,
    useInternalState,
    apiPath,
    dispatch,
  } = props;
  //   const [categoryDict, setCategoryDict] = useState({})
  const [loading, setLoading] = useState(true);
  const [categoryData, setCategoryData] = useState({
    categoryDict: {},
    categoryParentDict: {},
  });

  const categoryDict = useInternalState
    ? categoryData.categoryDict
    : category_widget.categoryDict;

  const categoryParentDict = useInternalState
    ? categoryData.categoryParentDict
    : category_widget.categoryParentDict;

  let [isShow, setIsShow] = useState(false);
  isShow = props.isOpen || isShow;

  const onClose = () => (props.onClose ? props.onClose() : setIsShow(false));

  const processCategoryData = (categoryList) => {
    categoryList.sort((a, b) =>
      a[modelName].position > b[modelName].position
        ? 1
        : b[modelName].position > a[modelName].position
        ? -1
        : 0
    );
    const categoryDict = {};
    const categoryObjDict = {};
    categoryList.forEach((v, i) => {
      categoryObjDict[v[modelName][itemId]] = v;
    });
    const categoryParentDict = {};
    categoryList.forEach((v, i) => {
      const children = categoryDict[v[modelName].parent_id || "main"] || [];
      children.push(v);
      categoryDict[v[modelName].parent_id || "main"] = children;

      const parent = categoryParentDict[v[modelName][itemId]] || [];
      let parentObj = undefined;
      for (i = 0; i < 10; i++) {
        parentObj =
          categoryObjDict[
            parentObj ? parentObj[modelName].parent_id : v[modelName].parent_id
          ];
        if (parentObj) {
          parent.unshift(parentObj);
        } else {
          break;
        }
      }
      parent.push(v);
      categoryParentDict[v[modelName][itemId]] = parent;
    });
    useInternalState
      ? setCategoryData({ categoryDict, categoryParentDict })
      : dispatch("category_widget", {
          categoryDict,
          categoryParentDict,
          categoryFlatDict: categoryObjDict,
        });
    //setCategoryDict(categoryDict)
  };

  const request = () => {
    if (!useInternalState && Object.keys(categoryDict).length !== 0) {
      setLoading(false);
      return;
    }
    //item_id_name=ecommerce_store_id&item_id=${ecommerceStoreId}
    dataSourceV2(`${modelName}_list?${parentItemId}=${parentItemIdValue}`, {
      url: apiPath,
    }).then((v) => {
      if (!v.message && v.item_list && v.item_list.length) {
        const items = v.item_list
          .map((v) => {
            const d = {};
            d[modelName] = v;
            return d;
          })
          .filter((v) => {
            const c = v[modelName];
            return (
              !c.is_hidden &&
              (!c.type || c.type === "primary" || c.type === "preview")
            );
          });
        processCategoryData(items);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    request();
  }, [parentItemIdValue]);

  const renderComponent = () => {
    if (loading) {
      return null;
    }
    if (type === "dropdown" || type === undefined) {
      return (
        <CategoryDropdown
          {...props}
          categoryDict={categoryDict}
          categoryParentDict={categoryParentDict}
          categoryFlatDict={category_widget.categoryFlatDict}
          onClose={onClose}
          modelName={modelName}
          itemId={itemId}
        />
      );
    }
    if (type === "dialog") {
      return (
        <CategoryDialog
          {...props}
          categoryDict={categoryDict}
          categoryParentDict={categoryParentDict}
          onClose={onClose}
          modelName={modelName}
          itemId={itemId}
        />
      );
    }
    if (type === "popover") {
      return (
        <CategoryPopover
          {...props}
          categoryDict={categoryDict}
          categoryParentDict={categoryParentDict}
          onClose={onClose}
          modelName={modelName}
          itemId={itemId}
        />
      );
    }
    return null;
  };

  return (
    <div className="u-flexCenter u-relative u-marginRight10 u-xs-margin0">
      {noButton ? null : button ? (
        <div onClick={() => setIsShow(true)}>{button}</div>
      ) : buttonMode === "icon" ? (
        <IconButton onClick={() => setIsShow(true)}>
          <Category />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          className="category-select-button u-border0 u-textColorNormal u-borderColorLighter u-backgroundWhite u-sizeFullWidth u-height40"
          style={{ height: "38px" }}
          onClick={() => setIsShow(true)}
        >
          <ViewHeadline className="u-marginRight5" /> Catalog
        </Button>
      )}
      {Boolean(isShow) ? renderComponent() : null}

      <CategoryDrawer
        {...props}
        categoryDict={categoryDict}
        categoryParentDict={categoryParentDict}
        categoryFlatDict={category_widget.categoryFlatDict}
        isOpen={type === "drawer" && Boolean(isShow)}
        onClose={onClose}
        modelName={modelName}
        itemId={itemId}
      />
    </div>
  );
}

export const category_widget = {
  categoryDict: {},
  categoryParentDict: {},
  categoryFlatDict: {},
};
