import React, {useState} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import "./style.styl";


export default function CategoryDropdown(props) {
    const {modelName, selected, itemId, categoryDict, categoryFlatDict, height, onSelect, onClose} = props;
    const [categorySelectedDict, setCategorySelectedDict] = useState({});

    const onCategorySelect = (id) => {
        categorySelectedDict[id] = true;
        setCategorySelectedDict(categorySelectedDict);

    };
    const onCategoryDelete = (id) => {
        delete categorySelectedDict[id];
        setCategorySelectedDict(categorySelectedDict);
    };
    const withFilter = (items) => items.filter(item => item.product_category.type === "primary" || item.product_category.type === "preview" || !item.product_category.type);

    const items = categoryDict.main || [];
    const categorySelected = categoryFlatDict[selected] || {};
    const categoryParentId = (categorySelected[modelName] || {}).parent_id;

    return (
        <React.Fragment>
            <div className="category-dropdown-cancel-area" style={{zIndex: 1300, backgroundColor: "rgba(0, 0, 0, 0.1)"}} onClick={onClose}></div>
                <div style={{zIndex: 1400}} className="category-dropdown-widget u-boxShadowThick u-absolute u-fontSize9 u-width240 u-borderRadius4 u-border1 u-borderColorLightest u-backgroundWhite">
                    <List className="u-relative">
                        {
                            withFilter(items).map((view, index) => {
                                const item = view[modelName] || {};
                                const itemIdValue = item[itemId];
                                const name = item.name;
                                const children = withFilter(categoryDict[itemIdValue] || []);
                                const childrenLen = children.length;
                                const isSelected = selected === itemIdValue || itemIdValue === categoryParentId;
                                return (
                                        <div key={index} className="category">
                                            <ListItem   className="u-cursorPointer u-backgroundTransparentBlackLightestHover"
                                                        selected={isSelected}
                                                        onClick={() => {onSelect(itemIdValue, name);onClose();}}
                                                        >
                                                        {name}
                                            </ListItem>
                                            {
                                                childrenLen ?
                                                    <div className="subcategory u-paddingVertical20 u-paddingRight20 u-absolute">
                                                        <div    className="u-marginLeft0 u-zIndex3 u-borderRadius4 u-border1 u-borderColorLightest u-backgroundWhite"
                                                                style={{width: childrenLen > 10 ? (childrenLen > 20 ? 750 : 500) : 250}}>
                                                                <List   className="subcategory-list u-cursorPointer u-flexColumn u-flexWrap"
                                                                        style={{maxHeight: height * 0.6, maxWidth: 249}}>
                                                                    {
                                                                        children.map((view, index) => {
                                                                            const item = view[modelName] || {};
                                                                            const itemIdValue = item[itemId];
                                                                            const name = item.name;
                                                                            const isSelected = selected === itemIdValue;

                                                                            return (
                                                                                <ListItem   key={index}
                                                                                            selected={isSelected}
                                                                                            onClick={() => {onSelect(itemIdValue, name);onClose();}}
                                                                                            className="u-backgroundTransparentBlackLightestHover">
                                                                                    {name}
                                                                                </ListItem>
                                                                            );
                                                                        })
                                                                    }
                                                                </List>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }


                                        </div>

                                );
                            })
                        }
                    </List>
                </div>
        </React.Fragment>
    );
}
