import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ExpandMore from "@material-ui/icons/ExpandMore";
import "./style.styl";


export default function CategoryList(props) {
    const {items, selected, modelName, itemId, categoryDict, withExpandButton, height, onSelect, onExpand, onClose} = props;

    return (
        <List>
            {
                items.map((view, index) => {
                    const item = view[modelName] || {};
                    const itemIdValue = item[itemId];
                    const name = item.name;
                    const hasChildren = (categoryDict[itemIdValue] || []).length;
                    return (
                        <ListItem   key={index}
                                    button
                                    selected={selected === itemIdValue}
                                    className={"u-paddingHorizontal24 u-textColorNormal"}
                                    onClick={() => {onSelect(itemIdValue, name, view); onExpand(itemIdValue);}}
                                    >
                                    <ListItemText primary={name} />
                                    {
                                        withExpandButton && hasChildren ?
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" className="u-padding5" onClick={() => onExpand(itemIdValue)}>
                                                    <ExpandMore className="u-textColorNormal"/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                            :
                                            null
                                    }
                        </ListItem>

                    );
                })
            }
        </List>
    );
}
