import React, { useEffect } from "react";

import { CartContent } from "./CartContent";
import { requestProductListTemplate } from "./Requests/requests";
import { calcPrice } from "../../utils/index";
import { getIdFromFeatureList } from "../utils";

import Dialog from "@material-ui/core/Dialog";

import { getFromLS, saveToLS } from "commons-ui/core/utils/index";
import CRUD from "commons-ui/core/CRUD";

export const Cart = (props) => {
  const {
    items,
    isOpen,
    isMobile,
    ecommerceStoreGroupId,
    apiPath,
    cart
  } = props;

  const onClose = () => props.dispatch("cart", { isOpen: false }, "close_cart");

  const getFilterQuery = () => {
    const res = [];
    res.push({
      terms: {
        "search_data.ecommerce_product_id": items.map((value) => value.id),
      },
    });
    return res;
  };

  const setItemData = (items) => {
      const cartItems = props.items;
      const result = cartItems.map((cartItem) => {
           const itemDb = items.itemList.filter(v => v.ecommerce_product_id === cartItem.id)[0];
           if (!itemDb) return null;

           const { final_price } = calcPrice(itemDb);
           return { ...cartItem, product: itemDb, price: final_price };
      }).filter((v) => !!v);

    props.dispatch("cart", { items: result });
  };

  const request = requestProductListTemplate({
    setItemData,
    getFilterQuery,
    path: "ecommerce_product_view_list",
    itemId: "ecommerce_product_id",
    postprocess: "default",
    apiPath,
    ecommerceStoreGroupId,
    ecommerceStoreId: cart.ecommerceStoreId,
    searchInStoreGroup: true,
    noFacet: true,
    hiddenDirect: true,
  });

  useEffect(() => {
    if (items && items.length > 0) {
      request({});
    }
  }, []);

  return (
    <Dialog
      fullScreen={isMobile}
      onClose={onClose}
      open={isOpen}
      maxWidth="sm"
      fullWidth
      className="tree-widget1 cart-dialog"
      scroll="paper"
    >
      <CartContent {...props} ecommerceStoreId={cart.ecommerceStoreId} onClose={onClose} />
    </Dialog>
  );

  return null;
};

export const cartReducer = (state, payload, action) => {

  if (action === "add_product" || action === "add_product_without_cart") {
    const ecommerceStoreId =
      payload.ecommerceStoreId || state.ecommerceStoreId || "";
    if (
      ecommerceStoreId &&
      state.ecommerceStoreId &&
      state.ecommerceStoreId !== ecommerceStoreId
    ) {
      state.items = [];
    }
    const product = payload.product || {};
    const featureList = payload.featureList || [];
    const featureId = getIdFromFeatureList(featureList);
    const fullId = product.ecommerce_product_id + "|" + featureId;

    let itemDb = CRUD(
      state.items,
      "fid",
      fullId,
      undefined,
      "GET"
    );
    const { final_price } = calcPrice(product, featureList);
    const qty = (itemDb.count || 0) + (payload.count || payload.value || 1);
    const item = {
      product,
      count: qty,
      qty,
      id: product.ecommerce_product_id,
      fid: fullId,
      price: final_price,
      featureDict: payload.featureDict || {},
      featureList: featureList,
      featureId: featureId
    };
    let items = CRUD(
      state.items,
      "fid",
      item.fid,
      item,
      itemDb.fid ? "UPDATE" : "CREATE"
    );
    let isOpen = action === "add_product_without_cart" ? false : true;
    saveToLS("cart", { ...state, items, ecommerceStoreId, isOpen });
    return { items, ecommerceStoreId, isOpen };
  } else if (action === "remove_product") {
    let items = CRUD(state.items, payload.fid ? "fid" : "id", payload.fid || payload.id, undefined, "DELETE");
    saveToLS("cart", { ...state, items });
    return { items };
  } else if (action === "change_count") {
    const items = CRUD(
      state.items,
      payload.fid ? "fid" : "id",
      payload.fid || payload.id,
      undefined,
      "UPDATE_PARTIAL",
      { key: "count", value: payload.value || 0 }
    );
    saveToLS("cart", { ...state, items });
    return { items };
  } else if (action === "close_cart" || action === "open_cart") {
    saveToLS("cart", { ...state, ...payload });
    return payload;
  } else if (action === "clear_cart") {
    payload = { items: [] };
    saveToLS("cart", { ...state, ...payload });
    return payload;
  }
};

export const cart = getFromLS("cart") || {
  newItem: {},
  isOpen: false,
  type: "drawer_flat",
  items: [],
  update: false,
  ecommerceStoreId: "",
};
