import React from "react";

import { CountWidget } from "./CountWidget";

import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Button from "../../Button";
import Delete from "@material-ui/icons/Delete";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Add from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItem from "@material-ui/core/ListItem";

import { calcPrice, renderNameVertical } from "../../../utils";

import "./CartContent.module.scss";

export const CartContent = (props) => {
  const { items, ecommerceStoreId, onClose, mediaPath, noImage, dispatch } = props;
  const total = items
    .reduce(
      (a, b) =>
        a +
        (calcPrice(b.product || {}, b.featureList).final_price || 0) *
          (b.count || 0),
      0
    )
    .toFixed(2);
  const isCartEmpty = !items.length;
  return (
    <div className="u-sizeFullHeight u-flexColumn">
      <div className=" u-flexCenter u-justifyContentSpaceBetween u-height65 u-paddingHorizontal15">
        <DialogTitle className="u-padding0"><span className="u-fontWeightBold u-textColorSecondary">Cart</span></DialogTitle>
        <IconButton
          className="u-marginRightNegative5"
          color="inherit"
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </div>
      <List className="cart-content u-paddingVertical10  u-borderColorLightest u-minHeight250">
        {isCartEmpty ? (
          <div className="u-sizeFullHeight u-flexColumn u-justifyContentCenter u-alignItemsCenter">
            <div className="u-paddingHorizontal50 u-paddingBottom20">
              <img
                className="u-sizeFullWidth u-hide"
                src="/static/img/empty_cart_2.png"
              />
            </div>
            <div className="u-textColorNormal u-textAlignCenter u-marginTop50">
              Empty Cart
            </div>
          </div>
        ) : null}
        {items.map((item, i) => {
          const product = item.product || {};
          const featureDict = item.featureDict || {};
          const featureList = item.featureList || [];
          const { price_fixed, discount_fixed, discount_percent } = calcPrice(
            product,
            featureList
          );
          const price = discount_percent ? discount_fixed : price_fixed;
          const path = product.images
            ? mediaPath + (product.images || "").split(",")[0]
            : noImage ||
              "https://assets.smallcase.com/images/smallcases/200/SCSB_0004.png";
          const noBorder =
            i === items.length - 1
              ? " u-border0 "
              : " u-borderBottom0 u-borderColorLightest ";
          return (
            <ListItem
              key={i}
              className={"u-paddingVertical0 u-marginBottom15 u-paddingRight0 " + noBorder}
            >
              <div className="u-flex u-sizeFullWidth u-paddingRight15">
                <div className="u-relative u-inlineBlock u-marginVertical4 u-verticalAlignTop u-marginRight15">
                  <div
                    className={
                      "image-widget-item u-relative u-width60 u-height60"
                    }
                    style={{
                      backgroundImage: `url(${path})`,
                      backgroundSize: "contain",
                    }}
                  ></div>
                </div>
                <div className="u-sizeFullWidth u-flexColumn">
                  <div className="name u-fontSize16 u-textColorBlack u-marginBottom5 u-xs-fontSize12">
                    {renderNameVertical(product, { featureList, features: [], nameClassName: "u-textColorBlack u-fontWeightBold"})}
                  </div>
                  <div className="u-flexCenter u-textColorBlack u-fontSize16 u-xs-fontSize16">
                    <div className={discount_percent ? "u-textColorRed" : ""}>
                      $ {price}
                    </div>
                    <div className="u-flexCenter u-marginLeft20">
                      <CountWidget
                        itemIdValue={item.id}
                        itemFullIdValue={item.fid}
                        value={item.count || 0}
                        dispatch={dispatch}
                      />
                  <div className="u-flexCenter u-marginLeft15">
                        <IconButton
                          className="u-padding2 u-opacity80"
                          onClick={() =>
                            dispatch("cart", { id: item.id, fid: item.fid }, "remove_product")
                          }
                        >
                          <Delete className="" fontSize="small"/>
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ListItem>
          );
        })}


      </List>
      <div className="u-marginHorizontal15 u-marginBottom10 u-flexCenter u-justifyContentSpaceBetween">
          <div></div>
          <Button color="primary" size="small" onClick={() => {dispatch("/store/" + ecommerceStoreId);onClose();}}>
              <Add className="u-marginRight5"/> Add More Items
          </Button>
      </div>
      {!isCartEmpty ? (
        <div className="cart-actions">
          <div className="u-marginHorizontal15 u-fontSize16 u-textColorBlack u-flexCenter u-xs-fontSize16 u-paddingRight15 u-paddingVertical15" style={{borderTop: "2px solid #C7073D"}}>
            <div>Total:</div>
            <div className="u-marginLeft10 u-fontWeightBold">$ {total}</div>
          </div>
          <div className="u-flexCenter u-justifyContentCenter u-paddingBottom20 u-paddingHorizontal15">
            <div
              className="u-height48 u-marginRight20 u-xs-marginRight10 u-xs-sizeFullWidth u-flexCenter u-textColorBlack u-cursorPointer u-marginLeftNegative30 u-xs-marginLeft0"
              onClick={() => {
                  dispatch("/store/" + ecommerceStoreId);
                onClose();
              }}
            >
              Continue Shopping
          </div>

            <Button
              className="u-height48 u-xs-marginLeft0 u-xs-sizeFullWidth"
              color="secondary"
              link-meta="/checkout"
              onClick={() => {
                onClose();
                dispatch("/checkout");
              }}
            >
              Checkout <ChevronRight />
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
