import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Radio from "@material-ui/core/Radio";
import ListItemText from "@material-ui/core/ListItemText";
import "./style.styl";


export default function CategoryList(props) {
    const {items, selected, modelName, isFirst, isLast, itemId, level, categoryDict, withExpandButton, height, onSelect, onClose} = props;

    return (
        <List className={"item-list " + (isLast ? "child " : "")} style={{marginLeft: 16 * (level + 1)}}>
            {
                items.map((view, index) => {
                    const item = view[modelName] || {};
                    const itemIdValue = item[itemId];
                    const name = item.name;
                    const isSelected = selected === itemIdValue;
                    return (
                        <ListItem   key={index}
                                    button
                                    className={"item "}
                                    onClick={() => (isFirst && isSelected) ? onSelect("none") : onSelect(itemIdValue)}
                                    >

                                    <ListItemText primary={name} />

                                        <ListItemIcon>
                                             <Radio
                                                checked={isSelected}
                                                value="b"
                                                edge="end"
                                                color="primary"
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ "aria-label": "B" }}
                                              />
                                        </ListItemIcon>
                        </ListItem>

                    );
                })
            }
        </List>
    );
}
