export const ROOT = "https://catch22market.com/api/";
export const websiteDomain = "https://catch22market.com";

const serverIp = "https://catch22market.com";//"localhost:8080/api/v1";//"https://catch22market.com"; //"localhost:8080/api/v1"

export const apiPath = `${
  serverIp.startsWith("http") ? serverIp : "http://" + serverIp
}${serverIp.startsWith("http") ? "/api" : ""}`;

export const mediaPath = `${
  serverIp.startsWith("http")
    ? serverIp + "/media/"
    : "http://" + serverIp.replace("/api/v1", "/mediafiles/")
}`;
const socketIp = "localhost";
export const socketPath = `ws://${socketIp}:6030`;

export const blogPath = "https://catch22.website";

export const ecommerceStoreGroupId = "7a51e295-4a1d-4750-b27d-cd7ff1ba85a0";//"2ff37240-6620-4c27-a816-fa0d11cefa6d";//"7a51e295-4a1d-4750-b27d-cd7ff1ba85a0";
export const ecommerceStoreId = "1ctwNsTXZvx7o3L7KaYb8CLWwPA";
export const blogEcommerceStoreId = "1YkYGGfJESnni2i8c40q3nZfBac";
export const blogEcommerceStoreGroupId = "1YkXoZbm1Oq4JhdYuidEK3qqPsx";
export const courseEcommerceStoreId = "1Z7aH0GW24iBpHVSH2B0NqPL4pa";
export const courseEcommerceStoreGroupId = "1YkXoZbm1Oq4JhdYuidEK3qqPsx";


// Group ID 7a51e295-4a1d-4750-b27d-cd7ff1ba85a0

export const ssrMode = typeof window === "undefined";

export const faviconDefault = "/favicon.ico";

export const META_DEFAULTS = {
  custom: [],
  description: "Default description",
  image: "",
  title: "Piroshky Piroshky Bakery",
  type: "website",
  url:  websiteDomain,
};
